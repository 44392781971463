<template>
    <div class="container" style="padding: 0.35rem">
        <h2>Token注入助手</h2>
        <van-field v-model="inputUserId" center clearable label="注入Token" placeholder="请输入目标的UserId">
            <template #button>
                <van-button size="small" type="primary" @click="generateToken">注入</van-button>
            </template>
        </van-field>
        <h2>
            换肤助手
        </h2>
        <!--    黑夜和白天-->
        <van-button type="primary" size="small" @click="changeTheme('DARK_MODE')" style="margin: 0.1rem">黑夜
        </van-button>
        <van-button type="primary" size="small" @click="changeTheme('SUN_MODE')" style="margin: 0.1rem">白天
        </van-button>

        <h2>路由枚举</h2>
        <van-row v-for="(routeGroup, index) in routerInfo" :key="index">
            <p>{{ routeGroup.name }}</p>
            <van-col v-for="(route, index2) in routeGroup.children" :key="index2">
                <template v-if="route.meta">
                    <van-button
                            type="primary"
                            size="small"
                            @click="goTo(route.path)"
                            style="margin: 0.1rem">
                        {{ route.meta.title }}
                    </van-button>
                </template>
                <template v-else>
                    <van-button
                            type="primary"
                            size="small"
                            @click="goTo(route.path)"
                            style="margin: 0.1rem">
                        {{ route.name }}
                    </van-button>
                </template>
            </van-col>
        </van-row>
        <h2>开发者工具-测试刷新页面</h2>
        <van-button type="primary" size="small" @click="refreshPage">刷新页面 当前时间：{{ new Date().toLocaleString() }}
        </van-button>
    </div>
</template>

<script>
import hp from "/src/router/modules/hp";
import crm from "/src/router/modules/crm";
import hoss from "/src/router/modules/hoss";
import devTools from "/src/router/modules/devTools";
import huipaoAi from "@/router/modules/huipao-ai";


export default {
    name: "index",
    // 生命周期
    data() {
        return {
            // 日志字符串
            routerInfo: [],
            // userId
            inputUserId: "",
        };
    },
    created() {

    },
    // 只有在页面被创建时执行一次
    mounted() {
        var total = [...hp, ...crm, ...hoss, ...huipaoAi, ...devTools]; // 拷贝一份
        var result = [];
        //深度拷贝
        for (var i = 0; i < total.length; i++) {
            result.push(JSON.parse(JSON.stringify(total[i])));
        }
        // 遍历所有children
        result.forEach((item) => {
            if (item.children) {
                item.children.forEach((child) => {
                    // 修正路径
                    child.path = item.path + '/' + child.path;
                });
            }
        });
        this.routerInfo = result;
    },
    methods: {
        // 刷新页面
        refreshPage() {
            window.location.reload();
        },
        goTo(path) {
            this.$router.push(path);
        },
        // 调用window._t方法
        async generateToken() {
            if (this.inputUserId === "") {
                this.$toast("请输入userId");
                return;
            }
            try {
                await window._t(this.inputUserId);
                this.$toast("生成成功");
            } catch (e) {
                this.$toast("生成失败，请检查输入的UserId是否正确");
            }
        },
        // document.body.className = "DARK_MODE";
        // Vue.prototype.$theme = "DARK_MODE";
        // localStorage.setItem("hoss-theme", "DARK_MODE");
        changeTheme(themeName) {
            document.body.className = themeName;
            Vue.prototype.$theme = themeName;
            localStorage.setItem("hoss-theme", themeName);
        }
    },
}
</script>

<style scoped>

.van-button {
    background: var(--mainColor);
    border: 1px solid var(--mainColor);
}

</style>
